
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Dropdowns from "@/core/config/Dropdowns";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditDropdownOptionModal from "@/components/modals/settings/dropdowns/EditDropdownOptionModal.vue";

export default defineComponent({
    name: "settings-dropdowns",
    props: {
        widgetClasses: String,
    },
    components: {
        EditDropdownOptionModal,
    },
    setup() {
        const { t, te } = useI18n();

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        let depends = ref('');
        let nameLabel = ref<string>(translate("dropdownOptionNameLabel"));
        let descriptionLabel = ref<string>(translate("dropdownOptionDescriptionLabel"));
        let descriptionRequired = ref<boolean>(false);
        let rootIndex = -1;
        let childIndex = -1;

        let dropdowns = Dropdowns;
        let loading = ref(false);
        let activeDropdown = ref('');
        let activeDropdownOption = ref<any>(null);
        const dropdownOptions = ref([]);

        const setDropdown = (type: string) => {
            activeDropdown.value = type;

            depends.value = '';

            dropdowns.map((dropdown, i) => {

                (dropdown.children as any[]).map((child, j) => {

                    if (child.key === type) {
                        rootIndex = i;
                        childIndex = j;

                        if (typeof child.depends != 'undefined') {
                            depends.value = child.depends;
                        }

                        if (typeof child.nameLabel != 'undefined') {
                            nameLabel.value = child.nameLabel;
                        }

                        if (typeof child.descriptionLabel != 'undefined') {
                            descriptionLabel.value = child.descriptionLabel;
                        }

                        if (typeof child.descriptionRequired != 'undefined') {
                            descriptionRequired.value = child.descriptionRequired;
                        }
                    }
                });
            });

            getDropdownOptions();
        };

        const create = () => {
            activeDropdownOption.value = {
                id: null,
                name: "",
                depends: depends,
                type: activeDropdown.value,
                display: 1,
                parent_id: 0,
                description: "",
                nameLabel: nameLabel.value,
                descriptionLabel: descriptionLabel.value,
                descriptionRequired: descriptionRequired.value,
            };
            const modal = new Modal(document.getElementById('kt_modal_edit_dropdown_option'));
            modal.show();
        };

        const edit = (dropdownOption) => {
            activeDropdownOption.value = dropdownOption;
            activeDropdownOption.value.nameLabel = nameLabel.value;
            activeDropdownOption.value.descriptionLabel = descriptionLabel.value;
            activeDropdownOption.value.descriptionRequired = descriptionRequired.value;

            const modal = new Modal(document.getElementById('kt_modal_edit_dropdown_option'));
            modal.show();
        };

        const getDropdownOptions = async () => {
            loading.value = true;

            await axios.get(ApiRoutes.dropdownOptions.index, { params: { type: activeDropdown.value } })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    dropdownOptions.value = response.data;
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        onMounted(() => {
            setCurrentPageBreadcrumbs(translate("dropdownOptionHeading"), [translate("settingsHeading")]);
        });

        return {
            translate,
            depends,
            nameLabel,
            descriptionLabel,
            dropdowns,
            dropdownOptions,
            setDropdown,
            activeDropdown,
            getDropdownOptions,
            activeDropdownOption,
            loading,
            create,
            edit,
        };
    },
});
