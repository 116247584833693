
import * as Yup from "yup";
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

export default defineComponent({
    name: "edit-dropdown-option-modal",
    emit: ["refreshData"],
    props: {
        dropdownOption: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        let isUpdate = false as boolean;
        const parents = ref([]);
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editDropdownOptionModalRef = ref<null | HTMLElement>(null);
        const dropdownOptionDefault = {
            id         : null,
            name       : "",
            type       : "",
            parent_id  : 0,
            depends    : "",
            display    : true,
            description: "",
        };
        const dropdownOptionData = ref<any>({});
        Object.assign(dropdownOptionData.value, dropdownOptionDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        let nameLabel = ref<string>(translate("dropdownOptionNameLabel"));
        let descriptionLabel = ref<string>(translate("dropdownOptionDescriptionLabel"));
        let descriptionRequired = ref(false);

        const validationSchema = Yup.object().shape({
            name: Yup.string().required().label("Désignation"),
            description: Yup.string().nullable().label("Description"),
            parent_id: Yup.number().nullable().label("Parent"),
            display: Yup.boolean().nullable().label("Affiché"),
        });

        const getParents = async () => {
            const response = await axios.get(ApiRoutes.dropdownOptions.index, { params: { type: dropdownOptionData.value.depends } });

            parents.value = response.data;
        };

        const formatDescription = () => {
            dropdownOptionData.value.description = `parent_id:${dropdownOptionData.value.parent_id}`;
        };

        const submit = (values, actions) => {
            if (!submitButtonRef.value) {
                return;
            }

            // Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            if (isUpdate && props.dropdownOption.id) dropdownOptionData.value.id = props.dropdownOption.id;

            axios.post(ApiRoutes.dropdownOptions.updateOrCreate, dropdownOptionData.value)
                .then((response: AxiosResponse) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editDropdownOptionModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        onMounted(() => {
            const modal = document.getElementById('kt_modal_edit_dropdown_option');

            if (modal) modal.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(dropdownOptionData.value, dropdownOptionDefault);
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                isUpdate = false;

                if (props.dropdownOption.hasOwnProperty('nameLabel')) nameLabel.value = props.dropdownOption.nameLabel;
                if (props.dropdownOption.hasOwnProperty('descriptionLabel')) descriptionLabel.value = props.dropdownOption.descriptionLabel;
                if (props.dropdownOption.hasOwnProperty('descriptionRequired')) descriptionRequired.value = props.dropdownOption.descriptionRequired;

                if (props.dropdownOption) {
                    isUpdate = true;

                    // Fill dropdownOption fields
                    Object.keys(props.dropdownOption).forEach((key) => {
                        if (dropdownOptionDefault.hasOwnProperty(key)) {
                            if (typeof dropdownOptionDefault[key] == 'boolean') dropdownOptionData.value[key] = Boolean(props.dropdownOption[key]);
                            else dropdownOptionData.value[key] = props.dropdownOption[key];
                        }
                    });

                    if (typeof props.dropdownOption.depends != 'undefined') {
                        getParents();
                    }
                }
            });
        });

        return {
            isUpdate,
            parents,
            translate,
            nameLabel,
            descriptionLabel,
            descriptionRequired,
            formatDescription,
            dropdownOptionData,
            validationSchema,
            submit,
            submitButtonRef,
            editDropdownOptionModalRef,
        };
    },
});
