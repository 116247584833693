const Dropdowns = [
    {
        heading: "Employés",
        children: [
            {
                key: "employee_position",
                label: "Postes d'employés",
            },
        ]
    },
    {
        heading: "Stock",
        children: [
            {
                key: "warehouse_type",
                label: "Type de magasin",
            },
        ]
    },
    {
        heading: "Prestataire",
        children: [
            {
                key: "supplier_type",
                label: "Type Prestataire",
            },
        ]
    },
    {
        heading: "Branche",
        children: [
            {
                key: "park_unit_heading",
                label: "Type de rubrique",
            },
        ]
    },
    {
        heading: "Véhicules",
        children: [
            {
                key: "vehicle_type",
                label: "Types de véhicule",
            },
            {
                key: "vehicle_brand",
                label: "Marques de véhicule"
            },
            {
                depends: "vehicle_brand",
                key: "vehicle_model",
                label: "Modèles de véhicule",
            },
            {
                key: "vehicle_color",
                label: "Couleurs",
            },
            {
                key: "vehicle_gearbox",
                label: "Boîtes de vitesses",
            },
            {
                key: "vehicle_fuel",
                label: "Energies",
            },
            {
                key: "vehicle_insurance_company",
                label: "Sociétés d'assurance",
            },
            {
                key: "vehicle_condition",
                label: "Conditions véhicle",
            },
            {
                key: "vehicle_control_agency",
                label: "Agences contrôle technique",
                nameLabel: "Nom agence",
                descriptionLabel: "N° agence",
            },
            {
                key: "vehicle_control_checkpoint",
                label: "Points de contrôle",
            },
            {
                key: "vehicle_maintenance_type",
                label: "Type de maintenance",
            },
            {
                key: "vehicle_maintenance_breakdown",
                label: "Type de panne",
            },
        ]
    },
    {
        heading: "Maintenance",
        children: [
            {
                key: "request_maintenance_type",
                label: "Type de maintenance",
            },
            {
                key: "request_maintenance_breakdown",
                label: "Type de panne",
            },
        ]
    },
    {
        heading: "Missions",
        children: [
            {
                key: "mission_itinerary",
                label: "Iténeraires",
                nameLabel: "De",
                descriptionRequired: true,
                descriptionLabel: "Vers",
            },
        ]
    }
];

export default Dropdowns;
